import idx from 'idx';

export const DEFAULT_POST_PER_PAGE = 15;
export const DEFAULT_LANG = 'en-us';
export const DEFAULT_SORT = 'desc'; // asc | desc

// Parameters
// pageSize: number
// order: string
// lang: string
// page: number
// full_text: string

const getPrismicPosts = async params => {
  const apiRef = await fetch('https://hakkei.prismic.io/api');
  const refJson = await apiRef.json();
  const ref = idx(refJson, _ => _.refs[0].ref);

  if (ref && params) {
    const queryParams = {
      ref,
      pageSize: params.pageSize || DEFAULT_POST_PER_PAGE,
      orderings: `[my.post.date ${params.order || DEFAULT_SORT}]`,
      lang: params.lang || DEFAULT_LANG,
      page: params.page || 1,
      q: `[[at(document.type, "blog_details")][not(my.blog.hide_from_feeds, true)]${params.full_text ? `[fulltext(document, "${params.full_text}")]` : ''
      }]`,
    };

    const url = new URL('https://hakkei.cdn.prismic.io/api/v2/documents/search');
    url.search = new URLSearchParams(Object.entries(queryParams)).toString();

    return fetch(url).then(async res => res.json());
  }
};

export default getPrismicPosts;
