import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import getPrismicPosts from '../../utils/prismicPostAPI';
import BlogList from './blogList';
import Tag from '../../components/Tag';
import HoverLink from '../../components/Button/HoverLink';
import HoverButton from '../../components/Button/HoverButton';

const Blog = () => {
  const tag = ['Tag long', 'Tag', 'Tag long 1', 'Tag 1', 'Tag long 2', 'Tag 2', 'Tag 3', 'Tag long 3', 'Tag 4', 'Tag long 4', 'Tag 5', 'Tag 6', 'Tag long 5', 'Tag 7', 'Tag long 6', 'Tag 8'];
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);

  const onPreviousClick = () => setPage(page - 1);
  const onNextClick = () => setPage(page + 1);

  const onPageSelected = pageNum => {
    setPage(pageNum);
  };

  useEffect(() => {
    getPrismicPosts({
      page,
      pageSize: 15,
    }).then(res => {
      setData(res);
      setDataLoaded(true);
    }).catch(console.log);
  }, [page]);

  return (
    <Layout>
      {dataLoaded &&
        <div className="container w-11/12 sm:w-10/12 px-0 mx-auto mt-12 lg:pl-24  lg:relative ">
          <div className="lg:w-10/12">
            <h2 className="text-5xl font-extralight">Blog</h2>
            <div className="mt-4 lg:m-0 lg:absolute lg:top-24 lg:w-56 lg:right-0">
              {tag.map(tag => (
                <Tag key={tag} text={tag} />
              ))}
            </div>
            <BlogList blogs={data?.results} />
            <div className="w-full my-24 text-center">
              <HoverButton buttonText="load more" className="text-d-purple-1" hoverBGColor="#3D47B2" />
            </div>
          </div>
        </div>}
    </Layout>
  );
};

export default Blog;
