import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const HoverButton = ({ buttonText, onClick, className, hoverBGColor }) => {
  className = `hover-underline bg-transparent ${className}`;

  return (
    <button type="button" className={className} style={{ '--bg-color': hoverBGColor }} onClick={onClick}>
      {buttonText}
    </button>
  );
};

export default HoverButton;
